<template>
  <div v-if="isOpen" class="share-modal" :class="`share-modal--${position}`">
    <svg
      class="share-modal__close"
      width="800px"
      height="800px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="closeShareModal"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.207 6.207a1 1 0 0 0-1.414-1.414L12 10.586 6.207 4.793a1 1 0 0 0-1.414 1.414L10.586 12l-5.793 5.793a1 1 0 1 0 1.414 1.414L12 13.414l5.793 5.793a1 1 0 0 0 1.414-1.414L13.414 12l5.793-5.793z"
        fill="#0062ae"
      />
    </svg>

    <div class="share-modal__icons">
      <div
        class="share-modal__icon share-modal__icon--facebook"
        @click="shareTo('facebook')"
      >
        <FacebookIcon />
      </div>
      <div
        class="share-modal__icon share-modal__icon--twitter"
        @click="shareTo('twitter')"
      >
        <TwitterIcon />
      </div>
      <div
        class="share-modal__icon share-modal__icon--whatsapp"
        @click="shareTo('whatsapp')"
      >
        <WhatsappIcon />
      </div>
      <div
        class="share-modal__icon share-modal__icon--email"
        @click="shareTo('email')"
      >
        <EmailIcon />
      </div>
    </div>
  </div>
</template>

<script setup>
import WhatsappIcon from '@/icons/WhatsappIcon.vue';
import TwitterIcon from '@/icons/TwitterIcon.vue';
import FacebookIcon from '@/icons/FacebookIcon.vue';
import EmailIcon from '@/icons/EmailIcon.vue';
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  dataForSharing: {
    type: Object,
    required: true,
  },
  position: {
    type: String,
    default: 'right',
  },
});

const emit = defineEmits(['close']);

const closeShareModal = () => {
  emit('close');
};

const shareTo = (type) => {
  let url = '';

  const shareTitle = props.dataForSharing.title;
  const shareURL = props.dataForSharing.url;
  const shareText = props.dataForSharing.text || '';

  if (type === 'facebook') {
    url = `https://www.facebook.com/sharer/sharer.php?u=${shareURL}&title=${shareTitle}`;
    //url = `https://www.facebook.com/sharer.php?u=${shareURL}&p[title]=${shareTitle}&p[summary]=${shareDescription}`;
  } else if (type === 'twitter') {
    url = `https://twitter.com/share?url=${shareURL}&title=${shareTitle}`;
  } else if (type === 'whatsapp') {
    url = `https://wa.me/?text=${shareTitle} ${shareURL}`;
  } else if (type === 'email') {
    url = `mailto:?subject=${shareTitle}&body=${shareText} ${shareURL}`;
  }

  if (url) window.open(url, '_blank');
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';
.share-modal {
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 100%;
  margin-bottom: 5px;
  width: 380px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 2px solid $mittel_dunkelblau_ci;
  border-radius: 100px;
  z-index: 100;

  &--center {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    path {
      fill: $mittel_dunkelblau_ci;
    }
  }

  &__heading {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  &__icons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex: 1;
  }

  &__icon {
    margin: 15px;
    cursor: pointer;
    transition: 0.4s ease;
    &:hover {
      opacity: 0.7;
    }
    & > * {
      width: 50px;
      height: 50px;
      display: block;
    }
  }
}
</style>
