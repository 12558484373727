<template lang="html">
  <div v-if="this.now >= this.endDate"></div>
  <div v-else class="candle-count-down">
    {{ delta }}
  </div>
</template>

<script>
export default {
  name: 'CandleCountDown',
  props: {
    extinguishingTime: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      endDate: new Date(this.extinguishingTime.replace(' ', 'T')),
      now: new Date(),
      timer: null,
      isShow: false,
    };
  },
  computed: {
    delta() {
      let delta = Math.floor((this.endDate - this.now) / 1000);
      let days = Math.floor(delta / 86400);
      delta -= days * 86400;
      let hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
      let minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;
      // let seconds = delta % 60;
      let dateStr = `${days}t. ${hours}h. ${minutes}m.`;
      return 'Noch ' + dateStr;
    },
  },
  created() {
    if (this.now > this.endDate) {
      // this.$router.push({
      //   name: 'page',
      //   params: {
      //     slug: this.$store.state.global.slugs.candles,
      //   },
      // });
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.now = new Date();
      if (this.now > this.endDate) {
        this.now = this.endDate;
        clearInterval(this.timer);

        // this.$router.push({
        //   name: 'page',
        //   params: {
        //     slug: this.$store.state.global.slugs.candles,
        //   },
        // });
      }
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped></style>

<style lang="scss" scoped>
@import 'styles';
</style>
