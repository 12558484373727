<template lang="html">
  <div class="message">
    <div class="message__wrapper">
      <div class="message__content">
        <slot />
      </div>
    </div>
    <div class="message__triangle"></div>
  </div>
</template>

<script>
export default {
  name: 'Message',
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
