<template lang="html">
  <div
    class="single-candle"
    :class="{
      'single-candle--before-preview':
        candleIsPreview && $store.state.donationCandles.form.currentStep === 1,
      'single-candle--preview': candleIsPreview,
    }"
  >
    <div class="single-candle__message">
      <Message v-if="!changeStatusIsShow">
        <div class="single-candle__message-head">
          <Back
            v-if="globalSettings"
            color="#0062AE"
            :custom-back="candleIsPreview"
            :label="globalSettings['button_names']['back_button']"
            @back="backStep"
          />
          <Button
            v-if="!candleIsPreview && !isMobile"
            style-button="border"
            @click="sharePage"
          >
            <span v-if="globalSettings">
              <img src="@/assets/images/share.svg" alt="Share" />
              {{ globalSettings['button_names']['share_message'] }}
            </span>
          </Button>
          <ShareModal
            v-if="!isMobile"
            v-click-outside="closeShareModal"
            :is-open="shareModalIsOpen"
            :data-for-sharing="{
              title: info.title,
              url: url,
              text: info.message,
            }"
            @close="closeShareModal"
          />
        </div>

        <div class="single-candle__message-content">
          <div class="single-candle__message-header">
            <div class="single-candle__message-header-column">
              <span v-if="globalSettings">
                {{ globalSettings['labels_and_titles']['from'] }}
              </span>
              {{ info.candle_from }}
            </div>
            <div class="single-candle__message-header-column">
              <span v-if="globalSettings">
                {{ globalSettings['labels_and_titles']['for'] }}
              </span>
              {{ info.candle_for }}
            </div>
          </div>
          <div class="single-candle__message-text">
            <div v-html="info.message"></div>
          </div>
        </div>
        <div
          v-if="info['candle_extinguishing_time'] && !isMobile"
          class="single-candle__message-count"
        >
          <CandleCountDown
            :extinguishing-time="info['candle_extinguishing_time']"
          />
        </div>
      </Message>
      <div v-else class="single-candle__thanks-heading">
        Vielen Dank für Ihre Spende
      </div>
    </div>
    <div v-if="!changeStatusIsShow" class="single-candle__image">
      <img
        v-if="litCandleIsShow"
        class="single-candle__light"
        src="@/assets/images/candle-light.png"
        alt="Candle Light"
      />
      <div v-if="litCandleIsShow" class="single-candle__flame">
        <div class="single-candle__flame-shadows"></div>
        <div class="single-candle__flame-top"></div>
        <div class="single-candle__flame-middle"></div>
        <div class="single-candle__flame-bottom"></div>
      </div>
      <img
        v-if="litCandleIsShow"
        class="single-candle__candle"
        src="@/assets/images/new-candles/candle-burning-1.png"
        alt="Lit Candles"
      />
      <img
        v-else-if="burnedCandleIsShow"
        class="single-candle__candle"
        src="@/assets/images/new-candles/candle-1.png"
        alt="Lit Candles"
      />
    </div>
    <div v-else class="single-candle__image single-candle__image--animation">
      <transition name="animation-lit-background">
        <img
          v-if="animationLitCandle"
          class="single-candle__light-animated"
          src="@/assets/images/candle-light.png"
          alt="Candle Light"
        />
      </transition>
      <transition name="animation-lit-candle">
        <div v-if="animationLitCandle" class="single-candle__flame">
          <div class="single-candle__flame-shadows"></div>
          <div class="single-candle__flame-top"></div>
          <div class="single-candle__flame-middle"></div>
          <div class="single-candle__flame-bottom"></div>
        </div>
      </transition>
      <img
        class="single-candle__candle"
        src="@/assets/images/new-candles/candle-1.png"
        alt="Lit Candles"
      />
    </div>
    <CandleCountDown
      v-if="info['candle_extinguishing_time'] && isMobile"
      :extinguishing-time="info['candle_extinguishing_time']"
    />
    <div
      v-if="isMobile"
      class="single-candle__button"
      :class="{ 'single-candle__button--hidden': changeStatusIsShow }"
    >
      <template v-if="!candleIsPreview">
        <Button style-button="border" @click="sharePage">
          <span v-if="globalSettings">
            <img src="@/assets/images/share.svg" alt="Share" />
            {{ globalSettings['button_names']['share_message'] }}
          </span>
        </Button>
        <ShareModal
          v-click-outside="closeShareModal"
          :is-open="shareModalIsOpen"
          :data-for-sharing="{
            title: info.title,
            url: url,
            text: info.message,
          }"
          @close="closeShareModal"
        />
      </template>

      <Button v-if="candleIsPreview" @click="nextStep">
        <span v-if="globalSettings">
          {{ globalSettings['button_names']['next'] }}
        </span>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import Back from '@/components/Back/Back.vue';
import Message from '@/components/Message/Message.vue';
import CandleCountDown from '@/components/CandleCountDown/CandleCountDown.vue';
import ShareModal from '@/components/ShareModal.vue';
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'SingleCandle',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    ShareModal,
    Button,
    Back,
    Message,
    CandleCountDown,
  },
  props: {
    typeSection: {
      type: String,
      required: true,
    },
    info: {
      type: Object,
      required: true,
    },
  },
  emits: ['nextStep', 'backStep'],
  data() {
    return {
      animationLitCandle: false,
      url: window.location.href,
      shareModalIsOpen: false,
    };
  },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
    litCandleIsShow() {
      return (
        this.info.status === 'lit' || this.typeSection === 'candle-preview'
      );
    },
    burnedCandleIsShow() {
      return (
        this.info.status === 'burned' && this.typeSection !== 'candle-preview'
      );
    },
    candleIsPreview() {
      return this.typeSection === 'candle-preview';
    },
    changeStatusIsShow() {
      return this.$store.state.donationCandles.changeStatusIsShow;
    },
    isMobile() {
      return this.$store.state.global.isMobile;
    },
  },
  mounted() {
    if (this.changeStatusIsShow) {
      setTimeout(() => {
        this.animationLitCandle = true;
        setTimeout(() => {
          this.$store.commit('donationCandles/setChangeStatusIsShow', false);
        }, 1000);
      }, 1000);
    }
  },
  methods: {
    closeShareModal() {
      this.shareModalIsOpen = false;
    },
    sharePage() {
      if (navigator.share) {
        navigator
          .share({
            title: this.info.title,
            url: window.location.href,
            text: this.info.message,
          })
          .then(() => {
            // console.log('Thanks for sharing!');
          });
        // .catch(console.error);
      } else {
        this.shareModalIsOpen = true;
      }
    },
    nextStep() {
      this.$emit('nextStep');
    },
    backStep() {
      this.$emit('backStep');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';

:deep() {
  .share-modal {
    margin-top: -10px;
  }
}

.single-candle__button {
  :deep() {
    .share-modal {
      top: unset;
      bottom: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
